const workorderManage = {
  state: {
    beginUpdate: 1,
    workOrderQuery: {}
  },
  mutations: {
    set_workorderManage_update (state, data) {
      state.beginUpdate = data
    },
    set_workorderManage_workOrderQuery (state, data) {
      state.workOrderQuery = data
    }
  },
  getters: {

  },
  actions: {

  }
}

export default workorderManage
